export const AboutCards = () => import('../../components/about/AboutCards.vue' /* webpackChunkName: "components/about-cards" */).then(c => wrapFunctional(c.default || c))
export const AboutChildren = () => import('../../components/about/AboutChildren.vue' /* webpackChunkName: "components/about-children" */).then(c => wrapFunctional(c.default || c))
export const AboutHero = () => import('../../components/about/AboutHero.vue' /* webpackChunkName: "components/about-hero" */).then(c => wrapFunctional(c.default || c))
export const AboutNumbers = () => import('../../components/about/AboutNumbers.vue' /* webpackChunkName: "components/about-numbers" */).then(c => wrapFunctional(c.default || c))
export const AboutPhotos = () => import('../../components/about/AboutPhotos.vue' /* webpackChunkName: "components/about-photos" */).then(c => wrapFunctional(c.default || c))
export const CardsNewsCard = () => import('../../components/cards/NewsCard.vue' /* webpackChunkName: "components/cards-news-card" */).then(c => wrapFunctional(c.default || c))
export const CardsPartnerCard = () => import('../../components/cards/PartnerCard.vue' /* webpackChunkName: "components/cards-partner-card" */).then(c => wrapFunctional(c.default || c))
export const CardsPersonCard = () => import('../../components/cards/PersonCard.vue' /* webpackChunkName: "components/cards-person-card" */).then(c => wrapFunctional(c.default || c))
export const CardsUserCard = () => import('../../components/cards/UserCard.vue' /* webpackChunkName: "components/cards-user-card" */).then(c => wrapFunctional(c.default || c))
export const AppArticle = () => import('../../components/app/AppArticle.vue' /* webpackChunkName: "components/app-article" */).then(c => wrapFunctional(c.default || c))
export const AppAvatar = () => import('../../components/app/AppAvatar.vue' /* webpackChunkName: "components/app-avatar" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/app/AppBanner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppBtn = () => import('../../components/app/AppBtn.vue' /* webpackChunkName: "components/app-btn" */).then(c => wrapFunctional(c.default || c))
export const AppButton = () => import('../../components/app/AppButton.vue' /* webpackChunkName: "components/app-button" */).then(c => wrapFunctional(c.default || c))
export const AppCollapse = () => import('../../components/app/AppCollapse.vue' /* webpackChunkName: "components/app-collapse" */).then(c => wrapFunctional(c.default || c))
export const AppEmpty = () => import('../../components/app/AppEmpty.vue' /* webpackChunkName: "components/app-empty" */).then(c => wrapFunctional(c.default || c))
export const AppFilters = () => import('../../components/app/AppFilters.vue' /* webpackChunkName: "components/app-filters" */).then(c => wrapFunctional(c.default || c))
export const AppGalleryDialog = () => import('../../components/app/AppGalleryDialog.vue' /* webpackChunkName: "components/app-gallery-dialog" */).then(c => wrapFunctional(c.default || c))
export const AppHcaptcha = () => import('../../components/app/AppHcaptcha.js' /* webpackChunkName: "components/app-hcaptcha" */).then(c => wrapFunctional(c.default || c))
export const AppHeading = () => import('../../components/app/AppHeading.vue' /* webpackChunkName: "components/app-heading" */).then(c => wrapFunctional(c.default || c))
export const AppIcon = () => import('../../components/app/AppIcon.vue' /* webpackChunkName: "components/app-icon" */).then(c => wrapFunctional(c.default || c))
export const AppInitials = () => import('../../components/app/AppInitials.vue' /* webpackChunkName: "components/app-initials" */).then(c => wrapFunctional(c.default || c))
export const AppLayout = () => import('../../components/app/AppLayout.vue' /* webpackChunkName: "components/app-layout" */).then(c => wrapFunctional(c.default || c))
export const AppList = () => import('../../components/app/AppList.vue' /* webpackChunkName: "components/app-list" */).then(c => wrapFunctional(c.default || c))
export const AppLoader = () => import('../../components/app/AppLoader.vue' /* webpackChunkName: "components/app-loader" */).then(c => wrapFunctional(c.default || c))
export const AppLoginModal = () => import('../../components/app/AppLoginModal.vue' /* webpackChunkName: "components/app-login-modal" */).then(c => wrapFunctional(c.default || c))
export const AppLogo = () => import('../../components/app/AppLogo.vue' /* webpackChunkName: "components/app-logo" */).then(c => wrapFunctional(c.default || c))
export const AppModeration = () => import('../../components/app/AppModeration.vue' /* webpackChunkName: "components/app-moderation" */).then(c => wrapFunctional(c.default || c))
export const AppPaginator = () => import('../../components/app/AppPaginator.vue' /* webpackChunkName: "components/app-paginator" */).then(c => wrapFunctional(c.default || c))
export const AppPhotosSwiper = () => import('../../components/app/AppPhotosSwiper.vue' /* webpackChunkName: "components/app-photos-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppPortal = () => import('../../components/app/AppPortal.js' /* webpackChunkName: "components/app-portal" */).then(c => wrapFunctional(c.default || c))
export const AppRange = () => import('../../components/app/AppRange.vue' /* webpackChunkName: "components/app-range" */).then(c => wrapFunctional(c.default || c))
export const AppResetModal = () => import('../../components/app/AppResetModal.vue' /* webpackChunkName: "components/app-reset-modal" */).then(c => wrapFunctional(c.default || c))
export const AppSearch = () => import('../../components/app/AppSearch.vue' /* webpackChunkName: "components/app-search" */).then(c => wrapFunctional(c.default || c))
export const AppSessionFavoriteSwitch = () => import('../../components/app/AppSessionFavoriteSwitch.vue' /* webpackChunkName: "components/app-session-favorite-switch" */).then(c => wrapFunctional(c.default || c))
export const AppSpinner = () => import('../../components/app/AppSpinner.vue' /* webpackChunkName: "components/app-spinner" */).then(c => wrapFunctional(c.default || c))
export const AppSwiper = () => import('../../components/app/AppSwiper.vue' /* webpackChunkName: "components/app-swiper" */).then(c => wrapFunctional(c.default || c))
export const AppTabs = () => import('../../components/app/AppTabs.vue' /* webpackChunkName: "components/app-tabs" */).then(c => wrapFunctional(c.default || c))
export const AppVideo = () => import('../../components/app/AppVideo.vue' /* webpackChunkName: "components/app-video" */).then(c => wrapFunctional(c.default || c))
export const FieldCaptcha = () => import('../../components/field/FieldCaptcha.vue' /* webpackChunkName: "components/field-captcha" */).then(c => wrapFunctional(c.default || c))
export const FieldCheckbox = () => import('../../components/field/FieldCheckbox.vue' /* webpackChunkName: "components/field-checkbox" */).then(c => wrapFunctional(c.default || c))
export const FieldFile = () => import('../../components/field/FieldFile.vue' /* webpackChunkName: "components/field-file" */).then(c => wrapFunctional(c.default || c))
export const FieldInput = () => import('../../components/field/FieldInput.vue' /* webpackChunkName: "components/field-input" */).then(c => wrapFunctional(c.default || c))
export const FieldSelect = () => import('../../components/field/FieldSelect.vue' /* webpackChunkName: "components/field-select" */).then(c => wrapFunctional(c.default || c))
export const FieldTextarea = () => import('../../components/field/FieldTextarea.vue' /* webpackChunkName: "components/field-textarea" */).then(c => wrapFunctional(c.default || c))
export const FieldWrapper = () => import('../../components/field/FieldWrapper.vue' /* webpackChunkName: "components/field-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ContestApplication = () => import('../../components/contest/ContestApplication.vue' /* webpackChunkName: "components/contest-application" */).then(c => wrapFunctional(c.default || c))
export const IndexArrowLink = () => import('../../components/index/IndexArrowLink.vue' /* webpackChunkName: "components/index-arrow-link" */).then(c => wrapFunctional(c.default || c))
export const IndexBack = () => import('../../components/index/IndexBack.vue' /* webpackChunkName: "components/index-back" */).then(c => wrapFunctional(c.default || c))
export const IndexCards = () => import('../../components/index/IndexCards.vue' /* webpackChunkName: "components/index-cards" */).then(c => wrapFunctional(c.default || c))
export const IndexCourses = () => import('../../components/index/IndexCourses.vue' /* webpackChunkName: "components/index-courses" */).then(c => wrapFunctional(c.default || c))
export const IndexEvents = () => import('../../components/index/IndexEvents.vue' /* webpackChunkName: "components/index-events" */).then(c => wrapFunctional(c.default || c))
export const IndexFooter = () => import('../../components/index/IndexFooter.vue' /* webpackChunkName: "components/index-footer" */).then(c => wrapFunctional(c.default || c))
export const IndexHero = () => import('../../components/index/IndexHero.vue' /* webpackChunkName: "components/index-hero" */).then(c => wrapFunctional(c.default || c))
export const IndexInventor = () => import('../../components/index/IndexInventor.vue' /* webpackChunkName: "components/index-inventor" */).then(c => wrapFunctional(c.default || c))
export const IndexInventorNew = () => import('../../components/index/IndexInventorNew.vue' /* webpackChunkName: "components/index-inventor-new" */).then(c => wrapFunctional(c.default || c))
export const IndexLogo = () => import('../../components/index/IndexLogo.vue' /* webpackChunkName: "components/index-logo" */).then(c => wrapFunctional(c.default || c))
export const IndexNews = () => import('../../components/index/IndexNews.vue' /* webpackChunkName: "components/index-news" */).then(c => wrapFunctional(c.default || c))
export const IndexNumbers = () => import('../../components/index/IndexNumbers.vue' /* webpackChunkName: "components/index-numbers" */).then(c => wrapFunctional(c.default || c))
export const IndexOnlineEvents = () => import('../../components/index/IndexOnlineEvents.vue' /* webpackChunkName: "components/index-online-events" */).then(c => wrapFunctional(c.default || c))
export const IndexPartners = () => import('../../components/index/IndexPartners.vue' /* webpackChunkName: "components/index-partners" */).then(c => wrapFunctional(c.default || c))
export const IndexPartnersNew = () => import('../../components/index/IndexPartnersNew.vue' /* webpackChunkName: "components/index-partners-new" */).then(c => wrapFunctional(c.default || c))
export const IndexPress = () => import('../../components/index/IndexPress.vue' /* webpackChunkName: "components/index-press" */).then(c => wrapFunctional(c.default || c))
export const IndexSectionHead = () => import('../../components/index/IndexSectionHead.vue' /* webpackChunkName: "components/index-section-head" */).then(c => wrapFunctional(c.default || c))
export const IndexSwiperFooter = () => import('../../components/index/IndexSwiperFooter.vue' /* webpackChunkName: "components/index-swiper-footer" */).then(c => wrapFunctional(c.default || c))
export const IndexVideos = () => import('../../components/index/IndexVideos.vue' /* webpackChunkName: "components/index-videos" */).then(c => wrapFunctional(c.default || c))
export const ChatMessages = () => import('../../components/chat/ChatMessages.vue' /* webpackChunkName: "components/chat-messages" */).then(c => wrapFunctional(c.default || c))
export const ChatModeration = () => import('../../components/chat/ChatModeration.vue' /* webpackChunkName: "components/chat-moderation" */).then(c => wrapFunctional(c.default || c))
export const ChatNotification = () => import('../../components/chat/ChatNotification.vue' /* webpackChunkName: "components/chat-notification" */).then(c => wrapFunctional(c.default || c))
export const ChatPrivate = () => import('../../components/chat/ChatPrivate.vue' /* webpackChunkName: "components/chat-private" */).then(c => wrapFunctional(c.default || c))
export const ChatPublic = () => import('../../components/chat/ChatPublic.vue' /* webpackChunkName: "components/chat-public" */).then(c => wrapFunctional(c.default || c))
export const InventorGoal = () => import('../../components/inventor/InventorGoal.vue' /* webpackChunkName: "components/inventor-goal" */).then(c => wrapFunctional(c.default || c))
export const InventorHero = () => import('../../components/inventor/InventorHero.vue' /* webpackChunkName: "components/inventor-hero" */).then(c => wrapFunctional(c.default || c))
export const InventorJury = () => import('../../components/inventor/InventorJury.vue' /* webpackChunkName: "components/inventor-jury" */).then(c => wrapFunctional(c.default || c))
export const InventorMap = () => import('../../components/inventor/InventorMap.vue' /* webpackChunkName: "components/inventor-map" */).then(c => wrapFunctional(c.default || c))
export const InventorNominations = () => import('../../components/inventor/InventorNominations.vue' /* webpackChunkName: "components/inventor-nominations" */).then(c => wrapFunctional(c.default || c))
export const InventorRequest = () => import('../../components/inventor/InventorRequest.vue' /* webpackChunkName: "components/inventor-request" */).then(c => wrapFunctional(c.default || c))
export const InventorStages = () => import('../../components/inventor/InventorStages.vue' /* webpackChunkName: "components/inventor-stages" */).then(c => wrapFunctional(c.default || c))
export const InventorTasks = () => import('../../components/inventor/InventorTasks.vue' /* webpackChunkName: "components/inventor-tasks" */).then(c => wrapFunctional(c.default || c))
export const EventAbout = () => import('../../components/event/EventAbout.vue' /* webpackChunkName: "components/event-about" */).then(c => wrapFunctional(c.default || c))
export const EventCard = () => import('../../components/event/EventCard.vue' /* webpackChunkName: "components/event-card" */).then(c => wrapFunctional(c.default || c))
export const EventPhoto = () => import('../../components/event/EventPhoto.vue' /* webpackChunkName: "components/event-photo" */).then(c => wrapFunctional(c.default || c))
export const EventProgram = () => import('../../components/event/EventProgram.vue' /* webpackChunkName: "components/event-program" */).then(c => wrapFunctional(c.default || c))
export const EventSpeakers = () => import('../../components/event/EventSpeakers.vue' /* webpackChunkName: "components/event-speakers" */).then(c => wrapFunctional(c.default || c))
export const EventVoteBtn = () => import('../../components/event/EventVoteBtn.vue' /* webpackChunkName: "components/event-vote-btn" */).then(c => wrapFunctional(c.default || c))
export const LiveAside = () => import('../../components/live/LiveAside.vue' /* webpackChunkName: "components/live-aside" */).then(c => wrapFunctional(c.default || c))
export const LiveBar = () => import('../../components/live/LiveBar.vue' /* webpackChunkName: "components/live-bar" */).then(c => wrapFunctional(c.default || c))
export const LiveDesc = () => import('../../components/live/LiveDesc.vue' /* webpackChunkName: "components/live-desc" */).then(c => wrapFunctional(c.default || c))
export const LiveHalls = () => import('../../components/live/LiveHalls.vue' /* webpackChunkName: "components/live-halls" */).then(c => wrapFunctional(c.default || c))
export const LiveModal = () => import('../../components/live/LiveModal.vue' /* webpackChunkName: "components/live-modal" */).then(c => wrapFunctional(c.default || c))
export const LiveParticipants = () => import('../../components/live/LiveParticipants.vue' /* webpackChunkName: "components/live-participants" */).then(c => wrapFunctional(c.default || c))
export const LiveQuestions = () => import('../../components/live/LiveQuestions.vue' /* webpackChunkName: "components/live-questions" */).then(c => wrapFunctional(c.default || c))
export const LiveQuestionsModal = () => import('../../components/live/LiveQuestionsModal.vue' /* webpackChunkName: "components/live-questions-modal" */).then(c => wrapFunctional(c.default || c))
export const LiveSessions = () => import('../../components/live/LiveSessions.vue' /* webpackChunkName: "components/live-sessions" */).then(c => wrapFunctional(c.default || c))
export const MediaFeedback = () => import('../../components/media/MediaFeedback.vue' /* webpackChunkName: "components/media-feedback" */).then(c => wrapFunctional(c.default || c))
export const PrevHero = () => import('../../components/prev/PrevHero.vue' /* webpackChunkName: "components/prev-hero" */).then(c => wrapFunctional(c.default || c))
export const PrevLectures = () => import('../../components/prev/PrevLectures.vue' /* webpackChunkName: "components/prev-lectures" */).then(c => wrapFunctional(c.default || c))
export const PrevMap = () => import('../../components/prev/PrevMap.vue' /* webpackChunkName: "components/prev-map" */).then(c => wrapFunctional(c.default || c))
export const PrevNumbers = () => import('../../components/prev/PrevNumbers.vue' /* webpackChunkName: "components/prev-numbers" */).then(c => wrapFunctional(c.default || c))
export const PrevPhotos = () => import('../../components/prev/PrevPhotos.vue' /* webpackChunkName: "components/prev-photos" */).then(c => wrapFunctional(c.default || c))
export const ProgramDays = () => import('../../components/program/ProgramDays.vue' /* webpackChunkName: "components/program-days" */).then(c => wrapFunctional(c.default || c))
export const ProgramGroups = () => import('../../components/program/ProgramGroups.vue' /* webpackChunkName: "components/program-groups" */).then(c => wrapFunctional(c.default || c))
export const ProgramHalls = () => import('../../components/program/ProgramHalls.vue' /* webpackChunkName: "components/program-halls" */).then(c => wrapFunctional(c.default || c))
export const ProgramSession = () => import('../../components/program/ProgramSession.vue' /* webpackChunkName: "components/program-session" */).then(c => wrapFunctional(c.default || c))
export const ProgramSessionItem = () => import('../../components/program/ProgramSessionItem.vue' /* webpackChunkName: "components/program-session-item" */).then(c => wrapFunctional(c.default || c))
export const ProgramSessionModal = () => import('../../components/program/ProgramSessionModal.vue' /* webpackChunkName: "components/program-session-modal" */).then(c => wrapFunctional(c.default || c))
export const ProgramSessions = () => import('../../components/program/ProgramSessions.vue' /* webpackChunkName: "components/program-sessions" */).then(c => wrapFunctional(c.default || c))
export const ProgramSpeakerItem = () => import('../../components/program/ProgramSpeakerItem.vue' /* webpackChunkName: "components/program-speaker-item" */).then(c => wrapFunctional(c.default || c))
export const ProgramSpeakerModal = () => import('../../components/program/ProgramSpeakerModal.vue' /* webpackChunkName: "components/program-speaker-modal" */).then(c => wrapFunctional(c.default || c))
export const ProgramTimeline = () => import('../../components/program/ProgramTimeline.vue' /* webpackChunkName: "components/program-timeline" */).then(c => wrapFunctional(c.default || c))
export const WidgetCaptcha = () => import('../../components/widget/WidgetCaptcha.vue' /* webpackChunkName: "components/widget-captcha" */).then(c => wrapFunctional(c.default || c))
export const WidgetCheckboxes = () => import('../../components/widget/WidgetCheckboxes.vue' /* webpackChunkName: "components/widget-checkboxes" */).then(c => wrapFunctional(c.default || c))
export const WidgetDatalist = () => import('../../components/widget/WidgetDatalist.vue' /* webpackChunkName: "components/widget-datalist" */).then(c => wrapFunctional(c.default || c))
export const WidgetDate = () => import('../../components/widget/WidgetDate.vue' /* webpackChunkName: "components/widget-date" */).then(c => wrapFunctional(c.default || c))
export const WidgetForm = () => import('../../components/widget/WidgetForm.vue' /* webpackChunkName: "components/widget-form" */).then(c => wrapFunctional(c.default || c))
export const WidgetItem = () => import('../../components/widget/WidgetItem.vue' /* webpackChunkName: "components/widget-item" */).then(c => wrapFunctional(c.default || c))
export const WidgetPassword = () => import('../../components/widget/WidgetPassword.vue' /* webpackChunkName: "components/widget-password" */).then(c => wrapFunctional(c.default || c))
export const WidgetPhoto = () => import('../../components/widget/WidgetPhoto.vue' /* webpackChunkName: "components/widget-photo" */).then(c => wrapFunctional(c.default || c))
export const WidgetTranslatableText = () => import('../../components/widget/WidgetTranslatableText.vue' /* webpackChunkName: "components/widget-translatable-text" */).then(c => wrapFunctional(c.default || c))
export const UserChats = () => import('../../components/user/UserChats.vue' /* webpackChunkName: "components/user-chats" */).then(c => wrapFunctional(c.default || c))
export const UserContestForm = () => import('../../components/user/UserContestForm.vue' /* webpackChunkName: "components/user-contest-form" */).then(c => wrapFunctional(c.default || c))
export const UserForm = () => import('../../components/user/UserForm.vue' /* webpackChunkName: "components/user-form" */).then(c => wrapFunctional(c.default || c))
export const UserRecommendedUsers = () => import('../../components/user/UserRecommendedUsers.vue' /* webpackChunkName: "components/user-recommended-users" */).then(c => wrapFunctional(c.default || c))
export const UserTest = () => import('../../components/user/UserTest.vue' /* webpackChunkName: "components/user-test" */).then(c => wrapFunctional(c.default || c))
export const UserUsersList = () => import('../../components/user/UsersList.vue' /* webpackChunkName: "components/user-users-list" */).then(c => wrapFunctional(c.default || c))
export const AppFooter = () => import('../../components/app/footer/AppFooter.vue' /* webpackChunkName: "components/app-footer" */).then(c => wrapFunctional(c.default || c))
export const AppFooterCopy = () => import('../../components/app/footer/AppFooterCopy.vue' /* webpackChunkName: "components/app-footer-copy" */).then(c => wrapFunctional(c.default || c))
export const AppFooterSocials = () => import('../../components/app/footer/AppFooterSocials.vue' /* webpackChunkName: "components/app-footer-socials" */).then(c => wrapFunctional(c.default || c))
export const AppHeader = () => import('../../components/app/header/AppHeader.vue' /* webpackChunkName: "components/app-header" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderAuth = () => import('../../components/app/header/AppHeaderAuth.vue' /* webpackChunkName: "components/app-header-auth" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderChats = () => import('../../components/app/header/AppHeaderChats.vue' /* webpackChunkName: "components/app-header-chats" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderLogin = () => import('../../components/app/header/AppHeaderLogin.vue' /* webpackChunkName: "components/app-header-login" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderLogo = () => import('../../components/app/header/AppHeaderLogo.vue' /* webpackChunkName: "components/app-header-logo" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderLogout = () => import('../../components/app/header/AppHeaderLogout.vue' /* webpackChunkName: "components/app-header-logout" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderMenu = () => import('../../components/app/header/AppHeaderMenu.vue' /* webpackChunkName: "components/app-header-menu" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderNav = () => import('../../components/app/header/AppHeaderNav.vue' /* webpackChunkName: "components/app-header-nav" */).then(c => wrapFunctional(c.default || c))
export const AppHeaderUser = () => import('../../components/app/header/AppHeaderUser.vue' /* webpackChunkName: "components/app-header-user" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendar = () => import('../../components/index/calendar/IndexCalendar.vue' /* webpackChunkName: "components/index-calendar" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendarDate = () => import('../../components/index/calendar/IndexCalendarDate.vue' /* webpackChunkName: "components/index-calendar-date" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendarDesktop = () => import('../../components/index/calendar/IndexCalendarDesktop.vue' /* webpackChunkName: "components/index-calendar-desktop" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendarEvent = () => import('../../components/index/calendar/IndexCalendarEvent.vue' /* webpackChunkName: "components/index-calendar-event" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendarEvents = () => import('../../components/index/calendar/IndexCalendarEvents.vue' /* webpackChunkName: "components/index-calendar-events" */).then(c => wrapFunctional(c.default || c))
export const IndexCalendarMobile = () => import('../../components/index/calendar/IndexCalendarMobile.vue' /* webpackChunkName: "components/index-calendar-mobile" */).then(c => wrapFunctional(c.default || c))
export const IndexHeader = () => import('../../components/index/header/IndexHeader.vue' /* webpackChunkName: "components/index-header" */).then(c => wrapFunctional(c.default || c))
export const IndexHeaderMenuMobile = () => import('../../components/index/header/IndexHeaderMenuMobile.vue' /* webpackChunkName: "components/index-header-menu-mobile" */).then(c => wrapFunctional(c.default || c))
export const ChatCompose = () => import('../../components/chat/compose/ChatCompose.vue' /* webpackChunkName: "components/chat-compose" */).then(c => wrapFunctional(c.default || c))
export const ChatComposeReply = () => import('../../components/chat/compose/ChatComposeReply.vue' /* webpackChunkName: "components/chat-compose-reply" */).then(c => wrapFunctional(c.default || c))
export const ChatList = () => import('../../components/chat/list/ChatList.vue' /* webpackChunkName: "components/chat-list" */).then(c => wrapFunctional(c.default || c))
export const ChatListItem = () => import('../../components/chat/list/ChatListItem.vue' /* webpackChunkName: "components/chat-list-item" */).then(c => wrapFunctional(c.default || c))
export const ChatMessage = () => import('../../components/chat/message/ChatMessage.vue' /* webpackChunkName: "components/chat-message" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageBubble = () => import('../../components/chat/message/ChatMessageBubble.vue' /* webpackChunkName: "components/chat-message-bubble" */).then(c => wrapFunctional(c.default || c))
export const ChatMessageReply = () => import('../../components/chat/message/ChatMessageReply.vue' /* webpackChunkName: "components/chat-message-reply" */).then(c => wrapFunctional(c.default || c))
export const LiveStream = () => import('../../components/live/stream/LiveStream.vue' /* webpackChunkName: "components/live-stream" */).then(c => wrapFunctional(c.default || c))
export const LiveStreamComdi = () => import('../../components/live/stream/LiveStreamComdi.vue' /* webpackChunkName: "components/live-stream-comdi" */).then(c => wrapFunctional(c.default || c))
export const ProgramParticipants = () => import('../../components/program/participants/ProgramParticipants.vue' /* webpackChunkName: "components/program-participants" */).then(c => wrapFunctional(c.default || c))
export const ProgramParticipantsGrid = () => import('../../components/program/participants/ProgramParticipantsGrid.vue' /* webpackChunkName: "components/program-participants-grid" */).then(c => wrapFunctional(c.default || c))
export const ProgramParticipantsSwiper = () => import('../../components/program/participants/ProgramParticipantsSwiper.vue' /* webpackChunkName: "components/program-participants-swiper" */).then(c => wrapFunctional(c.default || c))
export const UserCalendar = () => import('../../components/user/calendar/UserCalendar.vue' /* webpackChunkName: "components/user-calendar" */).then(c => wrapFunctional(c.default || c))
export const UserCalendarEvent = () => import('../../components/user/calendar/UserCalendarEvent.vue' /* webpackChunkName: "components/user-calendar-event" */).then(c => wrapFunctional(c.default || c))
export const UserCalendarSession = () => import('../../components/user/calendar/UserCalendarSession.vue' /* webpackChunkName: "components/user-calendar-session" */).then(c => wrapFunctional(c.default || c))
export const UserProfile = () => import('../../components/user/profile/UserProfile.vue' /* webpackChunkName: "components/user-profile" */).then(c => wrapFunctional(c.default || c))
export const UserProfileEdit = () => import('../../components/user/profile/UserProfileEdit.vue' /* webpackChunkName: "components/user-profile-edit" */).then(c => wrapFunctional(c.default || c))
export const UserProfileView = () => import('../../components/user/profile/UserProfileView.vue' /* webpackChunkName: "components/user-profile-view" */).then(c => wrapFunctional(c.default || c))
export const UserPayment = () => import('../../components/user/payment/UserPayment.vue' /* webpackChunkName: "components/user-payment" */).then(c => wrapFunctional(c.default || c))
export const UserPaymentUser = () => import('../../components/user/payment/UserPaymentUser.vue' /* webpackChunkName: "components/user-payment-user" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
